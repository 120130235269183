import React, { useState, useEffect } from 'react';
import CusNavBar from '../../global_components/navbar';
import Footer from '../../global_components/footer';
import { getAPICallv2 } from '../../services/helper';
import { useNavigate } from 'react-router';
import { getInvoiceDetail, deleteInvoice } from '../../services/accounts';
import { postInvoice } from '../../services/accounts';
import * as XLSX from 'xlsx';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { Oval } from 'react-loader-spinner';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Pagination,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

const ColorKey = () => (
  <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      bgcolor='#E7E8F4'
      p={1}
      borderRadius='8px'
      sx={{ border: '1px solid #2F39B6', marginBottom: '16px' }}
  >
      <Box display='flex' alignItems='center' mx={2}>
    <Box sx={{ width: 16, height: 16, backgroundColor: 'yellow', border: '1px solid #000', borderRadius: '4px', marginRight: '8px' }} />
    <Typography>PO Number Missing</Typography>
</Box>

<Box display='flex' alignItems='center' mx={2}>
    <Box sx={{ width: 16, height: 16, backgroundColor: 'orange', border: '1px solid #000', borderRadius: '4px', marginRight: '8px' }} />
    <Typography>Invoice Amount not matching PO Amount</Typography>
</Box>

<Box display='flex' alignItems='center' mx={2}>
    <Box sx={{ width: 16, height: 16, backgroundColor: 'red', border: '1px solid #000', borderRadius: '4px', marginRight: '8px' }} />
    <Typography>Invoice Amount not matching line Items</Typography>
</Box>

<Box display='flex' alignItems='center' mx={2}>
    <Box sx={{ width: 16, height: 16, backgroundColor: 'blue', border: '1px solid #000', borderRadius: '4px', marginRight: '8px' }} />
    <Typography>10 Days passed from invoice date</Typography>
</Box>

  </Box>
);



const FullScreenLoader = ({ isLoading }) => {
    if (!isLoading) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
        <Oval
          color="#FFFFFF" // You can change the spinner color
          height={80}
          width={80}
          secondaryColor="#4fa94d" // Secondary color
        />
      </div>
    );
  };

const LeftArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6L9 12L15 18" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  const RightArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 18L15 12L9 6" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  

const FsiAccountsPayable = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [popup, setPopup] = useState(false);
    const [invoicetodelete, setInvoicetodelete] = useState();
    const [invoices, setInvoices] = useState({
        approvement: [],
        posted: [],
        paid: [],
        failed: []
      });

      const handleExportToExcel = () => {
        // Access the invoices for the currently active tab
        const activeInvoices = invoices[activeTab];
      
        // Transform data for Excel
        const formattedData = activeInvoices.map(inv => {
          if (activeTab === 'paid') {
            return {
              'Invoice Number': inv.invoiceNumber ?? 'N/A',
              'Vendor': inv.vendor ?? 'N/A',
              'Amount': inv.totalAmount?.replace(/[$,]/g, '') ?? '0',
              'Check Number': inv.checkNumber ?? 'N/A',
              'Batch Number': inv.batchNumber ?? 'N/A',
            };
          } else {
            return {
              'Invoice Number': inv.invoiceNumber ?? 'N/A',
              'Vendor Name': inv.vendorName ?? 'N/A',
              'Total Amount': inv.totalAmount?.replace(/[$,]/g, '') ?? '0',
              'PO Amounts': inv.poAmounts?.replace(/[$,]/g, '') ?? '0',
              'PO Number': inv.poNumber ?? 'N/A',
              'Invoice Date': formatDateToUS(inv.invoiceDate) ?? 'Unknown Date',
              'GL Account': inv.glAccount ?? 'N/A',
            };
          }
        });
        
      
        // Create a new workbook and add a new sheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const sheetName = activeTab.charAt(0).toUpperCase() + activeTab.slice(1) + ' Invoices'; // Capitalize the first letter and add 'Invoices'
      
        // Append sheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      
        // Generate file and force download
        XLSX.writeFile(wb, `${sheetName}.xlsx`);
      };
      

const handleReloadData = () => {
  fetchAllInvoices();
};

const handleDeleteInvoice = (invoiceid) => {
  setPopup(true);
  setInvoicetodelete(invoiceid);
}

const deleteInvoiceConfirm = () => {
  deleteInvoice(invoicetodelete)
  fetchAllInvoices()
}


      const formatDateToUS = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad with leading 0 if needed
        const day = String(date.getDate()).padStart(2, '0');        // Get day and pad with leading 0 if needed
        const year = String(date.getFullYear()).slice(-2);          // Get last two digits of the year
        return `${month}-${day}-${year}`;                           // Return in MM-DD-YY format
      };
    
      const [selectedInvoices, setSelectedInvoices] = useState(new Set());
      const [activeTab, setActiveTab] = useState('approvement');
      const [isLoading, setIsLoading] = useState(false);
      const [currentPage, setCurrentPage] = useState(1);
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [searchTerm, setSearchTerm] = useState('');
      const [batchSearchTerm, setBatchSearchTerm] = useState('');  // New state for Batch Number search
      const [checkSearchTerm, setCheckSearchTerm] = useState('');  // New state for Check Number search
      const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
      const [vendorSearchTerm, setVendorSearchTerm] = useState('');
      const [poSearchTerm, setPoSearchTerm] = useState('');


      useEffect(()=>{
        setCurrentPage(1);
        setTotalSelectedAmount(0);
      },[activeTab]);

      useEffect(() => {
        const selectedInvoiceIds = [...selectedInvoices]; // Convert Set to array
      
        // Filter invoices by the selected invoice IDs
        const selectedInvoiceData = invoices[activeTab].filter(invoice => selectedInvoiceIds.includes(invoice.id));
      
        // Calculate the total amount for the selected invoices
        const totalAmount = selectedInvoiceData.reduce((sum, invoice) => {
          // Remove any non-numeric characters like '$' and ',' and convert to number
          const amount = parseFloat(invoice.totalAmount.replace(/[$,]/g, ''));
          return sum + amount;
        }, 0);
      
        setTotalSelectedAmount(totalAmount);
      }, [selectedInvoices]);
    
// Handle search input changes for Invoice Number
const handleSearch = (event) => {
  setSearchTerm(event.target.value.toLowerCase());
  setCurrentPage(1); // Reset to first page on new search
};

// Handle search input changes for Batch Number
const handleBatchSearch = (event) => {
  setBatchSearchTerm(event.target.value.toLowerCase());
  setCurrentPage(1); // Reset to first page on new search
};

// Handle search input changes for Check Number
const handleCheckSearch = (event) => {
  setCheckSearchTerm(event.target.value.toLowerCase());
  setCurrentPage(1); // Reset to first page on new search
};
    
      useEffect(() => {
        fetchAllInvoices();
      }, []);
    
      const fetchAllInvoices = async () => {
        setIsLoading(true);
        try {
            const statuses = ['approvement', 'posting', 'posted', 'paid', 'failed'];
            const allInvoices = {};
    
            // Fetch PO Summary first, and use that later for matching
            const poSummaryResponse = await getAPICallv2('/ap/get_po/');
            const poSummary = await poSummaryResponse.json();
    
            for (let status of statuses) {
                // If the status is "paid", fetch data from the move-to-paid endpoint
                let response;
                if (status === 'paid') {
                    response = await getAPICallv2(`/ap/invoices/move-to-paid`);
                } else {
                    response = await getAPICallv2(`/ap/invoice_list/?status=${status}&sort=created_at-desc`);
                }
    
                const data = await response.json();
    
                // Pass poSummary to map PO amounts to the invoices, except for "paid" invoices
const transformedData = status === 'paid' 
? await transformPaidData(data.data)  // Await the result directly
: await transformData(data.data, poSummary);  // Await the result directly
    
                allInvoices[status] = transformedData;
            }
    
            setInvoices(allInvoices);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching invoices:', error);
            setIsLoading(false);
        }
    };    

    const transformPaidData = (apiData) => {
      return apiData.map(async (invoice) => {
          return {
              invoiceNumber: invoice.invoice_number,
              vendor: invoice.vendor,
              totalAmount: `$${invoice.amount}`,
              checkNumber: invoice.check_number || 'N/A',
              batchNumber: invoice.batch_number || 'N/A',
          };
      });
  };

    // Use poSummary to fetch PO Amounts
    const getPoAmountFromSummary = (poNumber, poSummary) => {
        const poEntry = poSummary.find(po => po.hasOwnProperty(poNumber));
        return poEntry ? poEntry[poNumber] : 'N/A';
    };
    const transformData = async (apiData, poSummary) => {
      // Use Promise.all to handle async operations inside map
      const transformedData = await Promise.all(
          apiData.map(async (invoice) => {
              // Safely retrieve values, provide defaults where necessary
              const poAmount = invoice.invoice_details.po_number?.value 
                  ? getPoAmountFromSummary(invoice.invoice_details.po_number.value, poSummary)
                  : '0';
  
              // Calculate the total line price
              const totalLinePrice = invoice.line_items.reduce((sum, item) => sum + (item.line_price || 0), 0);
  
              // Check if there is a total amount conflict
              const totalconflict = invoice.invoice_details.invoice_amount?.value !== totalLinePrice;
  
              // Check if there is a PO conflict (if PO Number is null or empty)
              const poconflict = !invoice.invoice_details.po_number?.value;
  
              const orderconflict = poAmount === 'N/A' || poAmount === '' || poAmount !== invoice.invoice_details.invoice_amount?.value;
  
              // Check if more than 10 days have passed since the invoice date
              const invoiceDate = new Date(invoice.invoice_details.invoice_date?.value || Date.now());  // Default to current date if no date provided
              const currentDate = new Date();
              const diffInTime = currentDate - invoiceDate;
              const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert milliseconds to days
  
              const dateConflict = diffInDays > 10; // True if more than 10 days have passed
  
              return {
                  id: invoice.id,
                  vendorName: invoice.invoice_details.seller_name?.value || 'Unknown Vendor',  // Provide default if null
                  vendorId: invoice.invoice_details.vendor_id?.value || 'Unknown Vendor ID',   // Provide default if null
                  totalAmount: `$${invoice.invoice_details.invoice_amount?.value || '0'}`,     // Provide default if null
                  poAmounts: `$${poAmount}`, // Always return poAmount as string with $ sign
                  poNumber: invoice.invoice_details.po_number?.value || 'N/A',
                  invoiceNumber: invoice.invoice_details.invoice_number?.value || 'N/A',
                  invoiceDate: invoice.invoice_details.invoice_date?.value || 'Unknown Date',
                  glAccount: invoice.invoice_details.client_number?.value || 'N/A',
                  totalconflict,  // Add the total conflict boolean
                  poconflict,     // Add the PO conflict boolean
                  orderconflict,
                  dateConflict,   // Add the date conflict boolean
              };
          })
      );
  
      return transformedData;  // Return the resolved transformed data
  };
  
  
    
      const indexOfLastItem = currentPage * rowsPerPage;
      const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    
      const currentItems = invoices[activeTab]
      .filter(invoice => {
        const matchesInvoice = invoice.invoiceNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
        const matchesVendor = invoice.vendorName?.toLowerCase().includes(vendorSearchTerm) ?? false;
        const matchesPO = invoice.poNumber?.toLowerCase().includes(poSearchTerm) ?? false;
    
        // If it's the 'paid' tab, consider batch and check number as well
        if (activeTab === 'paid') {
          const matchesBatch = invoice.batchNumber?.toLowerCase().includes(batchSearchTerm.toLowerCase()) ?? false;
          const matchesCheck = invoice.checkNumber?.toLowerCase().includes(checkSearchTerm.toLowerCase()) ?? false;
          return matchesInvoice && matchesBatch && matchesCheck && matchesVendor && matchesPO;
        }
    
        return matchesInvoice && matchesVendor && matchesPO;
      })
      .slice(indexOfFirstItem, indexOfLastItem);
    
    
      const totalPages = Math.ceil(invoices[activeTab].length / rowsPerPage);
    
      const handlePreviousPage = () => {
        setCurrentPage(prev => prev - 1);
      };
    
      const handleNextPage = () => {
        setCurrentPage(prev => prev + 1);
      };
    
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1); // Reset to first page with new rows per page
      };
    
      const statusLabels = {
        approvement: "Active Invoices",
        posting: "Posting Invoices",
        posted: "Posted Invoices",
        paid: "Paid Invoices",
        failed: "Failed Invoices"
      };
    
      const toggleInvoiceSelection = (invoiceId) => {
        const newSelection = new Set(selectedInvoices);
        if (newSelection.has(invoiceId)) {
          newSelection.delete(invoiceId);
        } else {
          newSelection.add(invoiceId);
        }
        setSelectedInvoices(newSelection);
      };
    
      const toggleSelectAll = () => {
        const allInvoiceIds = invoices[activeTab].map(invoice => invoice.id);
        const newSelection = new Set(selectedInvoices);
    
        if (allInvoiceIds.every(id => selectedInvoices.has(id))) {
          allInvoiceIds.forEach(id => newSelection.delete(id));
        } else {
          allInvoiceIds.forEach(id => newSelection.add(id));
        }
        
        setSelectedInvoices(newSelection);
      };
    
      const isAllSelectedOnCurrentPage = currentItems.every(invoice => selectedInvoices.has(invoice.id));
      const isAllSelectedAcrossPages = invoices[activeTab].length > 0 && invoices[activeTab].every(invoice => selectedInvoices.has(invoice.id));

      const handlePostInvoice = async (id) => {
        let api_data;
            try {
                const invoice_response = await getInvoiceDetail(id);
                if (invoice_response.status === 200) {
                    const invoice_result = await invoice_response.json();
                    api_data = invoice_result; // Initialize here
                    api_data["line_items"] = invoice_result['invoice_items'];
                    const postingResponse = await postInvoice(api_data);
                    console.log(postingResponse)
                    // Assume a successful response means it was okay to add to successfulInvoices
                    if (postingResponse.status === 200) {
                        enqueueSnackbar('The invoice have been submitted.', {variant: "success"});
                        fetchAllInvoices();
                    }
                }
            } catch (error) {
                console.error('Error processing invoice for ID', id, ':', error);
                enqueueSnackbar("Failed to Post", {variant:"error"})
            }
      }

      const handlePostSelectedInvoices = async () => {
        setIsLoading(true);
    
        try {
            console.log(selectedInvoices);
            
            // Convert selectedInvoices Set to an array
            const selectedInvoicesArray = Array.from(selectedInvoices);
            
            // Fetch invoice details for all selected invoices
            const invoiceDetailsPromises = selectedInvoicesArray.map(id => getInvoiceDetail(id));
            const invoiceDetailsResponses = await Promise.all(invoiceDetailsPromises);
    
            // Process each response and prepare the data to post
            const invoicesToPost = invoiceDetailsResponses
                .filter(response => response.status === 200) // Only keep successful responses
                .map(async (response) => {
                    const invoiceResult = await response.json();
                    const api_data = {
                        ...invoiceResult,
                        line_items: invoiceResult.invoice_items
                    };
                    return api_data;
                });
    
            // Now wait for all invoice data to be prepared
            const preparedInvoices = await Promise.all(invoicesToPost);
            // Post all invoices together (assuming postInvoice expects an array of invoices)
            const postingResponse = await postInvoice(preparedInvoices);
    
            // Handle the single response for the batch of invoices
            if (postingResponse.ok) {
                enqueueSnackbar('The invoice have been submitted.', {variant: "success"});
                fetchAllInvoices(); // Assuming you still want to refresh the invoice list

            } else {
                enqueueSnackbar(postingResponse, {variant: "error"});
            }
    
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error, {variant: "error"});
        }
    
        setIsLoading(false);
    };
    
    

    const handleViewInvoice = (invoiceId) => {
      const url = `/invoice/${invoiceId}`; // Assuming you have a route like this set up in your app
      window.open(url, '_blank'); // Opens in a new tab
  };
    


  return (
    <div>
      {popup && (
        <Dialog
        open={popup}
        onClose={() => setPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopup(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            deleteInvoiceConfirm(); // Your function to delete the invoice
            setPopup(false);
          }} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      )
      }
      <CusNavBar />
      {isLoading ? (
        <FullScreenLoader isLoading={isLoading}/>
      ) : (
        <div className='flex flex-col justify-center items-center mt-10'>
          <h1 className='font-bold mb-4'>Accounts Payable Process</h1>
          <div><ColorKey/></div>
          <div className='w-full mx-4'>

          <div className="search-container my-4" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
          <input
    type="text"
    placeholder="Search Invoice Number..."
    value={searchTerm}
    onChange={handleSearch}
    style={{
      padding: '10px 15px',
      width: '300px',
      borderRadius: '40px',
      border: '1px solid #ccc',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      fontSize: '16px',
    }}
  />
  <input
    type="text"
    placeholder="Search Vendor Name..."
    value={vendorSearchTerm}
    onChange={(e) => setVendorSearchTerm(e.target.value.toLowerCase())}
    style={{
      padding: '10px 15px',
      width: '300px',
      borderRadius: '40px',
      border: '1px solid #ccc',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      fontSize: '16px',
      marginLeft: '10px',
    }}
  />
  <input
    type="text"
    placeholder="Search PO Number..."
    value={poSearchTerm}
    onChange={(e) => setPoSearchTerm(e.target.value.toLowerCase())}
    style={{
      padding: '10px 15px',
      width: '300px',
      borderRadius: '40px',
      border: '1px solid #ccc',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      fontSize: '16px',
      marginLeft: '10px',
    }}
  />
  {activeTab === 'paid' && (
                <>
                  <input
                    type="text"
                    placeholder="Search Batch Number..."
                    value={batchSearchTerm}
                    onChange={handleBatchSearch}
                    style={{
                      padding: '10px 15px',
                      width: '300px',
                      borderRadius: '40px',
                      border: '1px solid #ccc',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      fontSize: '16px',
                      marginLeft: '10px'
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search Check Number..."
                    value={checkSearchTerm}
                    onChange={handleCheckSearch}
                    style={{
                      padding: '10px 15px',
                      width: '300px',
                      borderRadius: '40px',
                      border: '1px solid #ccc',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      fontSize: '16px',
                      marginLeft: '10px'
                    }}
                  />
                </>
              )}
</div>
<div className="search-container my-4" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
          <button onClick={handleReloadData} className='rounded-3xl bg-blue-400 hover:bg-blue-500 text-white text-lg font-bold px-4 mr-2'>
            <RefreshIcon/>
  Reload Data
</button>
          <button onClick={handleExportToExcel} className='rounded-3xl bg-green-400 hover:bg-green-500 text-white text-lg font-bold p-1 px-4 mr-2'>
          <DownloadIcon/>
  Export to Excel
</button>
          <button className='rounded-3xl bg-blue-100 hover:bg-blue-200 text-black text-lg font-bold p-1 px-4 mr-2' onClick={()=>{navigate('/fsi-checkrun-upload')}}><UploadIcon/>Upload Check-Run File</button>
 
</div>
    
            <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
              {Object.keys(invoices).map((status) => (
                <div
                  key={status}
                  style={activeTab === status ? activeTabStyle : tabStyle}
                  onClick={() => setActiveTab(status)}
                >
                  {statusLabels[status] || `${status.charAt(0).toUpperCase()}${status.slice(1)} Invoices`} {/* Fallback for any status not explicitly mapped */}
                </div>
              ))}
            </div>
            
            
            <div style={{ overflowX: 'auto' }}>
            <table style={tableStyle}>
    <thead>
      <tr style={{ backgroundColor: '#f2f2f2' }}>
        <th style={thTdStyle}><input
  type="checkbox"
  onChange={toggleSelectAll}
  checked={isAllSelectedAcrossPages}
/></th>
        {activeTab !== 'paid' && (
          <>
            <th style={thTdStyleVendor}>Vendor Name</th>
            <th style={thTdStyle}>Vendor ID</th>
            <th style={thTdStyle}>Total Amount</th>
            <th style={thTdStyle}>PO Amounts</th>
            <th style={thTdStyle}>PO Number</th>
            <th style={thTdStyle}>Invoice Number</th>
            <th style={thTdStyle}>Invoice Date</th>
            <th style={thTdStyle}>GL Account</th>
          </>
        )}
        {activeTab === 'paid' && (
          <>
            <th style={thTdStyleVendor}>Invoice Number</th>
            <th style={thTdStyle}>Vendor</th>
            <th style={thTdStyle}>Amount</th>
            <th style={thTdStyle}>Check Number</th>
            <th style={thTdStyle}>Batch Number</th>
          </>
        )}
        <th style={thTdStyle}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {currentItems.map((invoice, index) => (
        <tr key={index} style={activeTab !== 'paid' ? (
          invoice.totalconflict
            ? { backgroundColor: '#f8d7da' } // Light red for total conflict
            : invoice.poconflict
            ? { backgroundColor: '#fff3cd' } // Light yellow for PO conflict
            : invoice.orderconflict
            ? { backgroundColor: '#f7c368' } // Light orange for order conflict
            : invoice.dateConflict
            ? { backgroundColor: '#d1ecf1' } // Light blue for date conflict
            : {}
        ) : {}}>      
          <td style={thTdStyle}>
              <input
                  type="checkbox"
                  checked={selectedInvoices.has(invoice.id)}
                  onChange={() => toggleInvoiceSelection(invoice.id)}
              />
          </td>
          {activeTab !== 'paid' && (
  <>
    <td style={thTdStyleVendor}>{invoice.vendorName ?? 'N/A'}</td>
        <td style={thTdStyle}>{invoice.vendorId ?? 'N/A'}</td>
        <td style={thTdStyle}>{parseFloat(invoice.totalAmount?.replace(/[$,]/g, '') ?? 0).toFixed(2)}</td>
        <td style={thTdStyle}>{parseFloat(invoice.poAmounts?.replace(/[$,]/g, '') ?? 0).toFixed(2)}</td>
        <td style={thTdStyle}>{invoice.poNumber ?? 'N/A'}</td>
        <td style={thTdStyle}>{invoice.invoiceNumber ?? 'N/A'}</td>
        <td style={thTdStyle}>{formatDateToUS(invoice.invoiceDate) ?? 'Unknown Date'}</td>
        <td style={thTdStyle}>{invoice.glAccount ?? 'N/A'}</td>
  </>
)}
{activeTab === 'paid' && (
  <>
    <td style={thTdStyleVendor}>{invoice.vendor ?? 'N/A'}</td>
        <td style={thTdStyle}>{invoice.invoiceNumber ?? 'N/A'}</td>
        <td style={thTdStyle}>{parseFloat(invoice.totalAmount?.replace(/[$,]/g, '') ?? 0).toFixed(2)}</td>
        <td style={thTdStyle}>{invoice.checkNumber ?? 'N/A'}</td>
        <td style={thTdStyle}>{invoice.batchNumber ?? 'N/A'}</td>
  </>
)}

          <td style={thTdStyle}>
          <button 
          onClick={() => handleViewInvoice(invoice.id)}
          style={buttonStyleView}
        >
          View
        </button>
          {activeTab === 'approvement' && (
            <>
            <button onClick={()=>{handleDeleteInvoice(invoice.id)}} style={buttonStyleDelete}>Delete</button>
            <button onClick={()=>{handlePostInvoice(invoice.id)}} style={buttonStylePost}>Post</button>
            </>
          )}
          {activeTab === 'failed' && (
            <>
            <button onClick={()=>{handleDeleteInvoice(invoice.id)}} style={buttonStyleDelete}>Delete</button>
            <button onClick={()=>{handlePostInvoice(invoice.id)}} style={buttonStylePost}>Post</button>
            </>
          )}
          </td>
        </tr>
      ))}
    </tbody>
</table>
              <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              <LeftArrowIcon />
            </button>
            <span style={{ margin: '0 12px' }}>{`Page ${currentPage} of ${totalPages}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              <RightArrowIcon />
            </button>
            <select className='text-lg p-2' onChange={handleRowsPerPageChange} value={rowsPerPage} style={{ marginLeft: 'auto' }}>
              {[10, 20, 30, 50].map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
          </div>
          <div className='mt-4 font-bold text-2xl flex flex-row items-start justify-start'>
            <span className='font-normal'>Total: </span> ${totalSelectedAmount.toFixed(2)}
              </div>
          <div className='mt-4 flex flex-row items-end justify-end'>
            {activeTab==='approvement' && (
                <button onClick={handlePostSelectedInvoices} className='rounded-xl bg-blue-800 hover:bg-blue-900 text-white text-lg font-bold p-1 px-4'>Post All</button>
            )}
              </div>
            </div>
          </div>
        </div>
      )}
      <footer className="text-center py-4 mt-5 text-muted border-t-2 border-gray-300 bg-slate-50">
                Created by Blue Ocean Strategic Partners &middot; &copy; {new Date().getFullYear()} | For support please send an email to our <a href="mailto:support@blueoceansp.atlassian.net">Help Desk</a>.
       </footer>
    </div>
  );
};

const tabStyle = {
  padding: '10px 20px',
  cursor: 'pointer',
  border: '1px solid #ccc',
  borderBottom: 'none',
  backgroundColor: '#f0f0f0',
};

const activeTabStyle = {
  ...tabStyle,
  backgroundColor: 'white',
  borderBottom: '2px solid blue',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
};

const thTdStyleVendor = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
};

const thTdStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'right',
};

const buttonStyleView = {
  backgroundColor: 'blue',
  color: 'white',
  padding: '5px 10px',
  margin: '0 5px',
  cursor: 'pointer',
};

const buttonStylePost = {
  backgroundColor: 'green',
  color: 'white',
  padding: '5px 10px',
  margin: '0 5px',
  cursor: 'pointer',
};

const buttonStyleDelete = {
  backgroundColor: 'red',
  color: 'white',
  padding: '5px 10px',
  margin: '0 5px',
  cursor: 'pointer',
};

export default FsiAccountsPayable;