import React, {useEffect, useState, useContext} from 'react';
import '../styles/login.css';
import {useLocation, useNavigate} from "react-router";
import Block from './block';
import APBlock from './ap_block';
import Footer from './footer';
import PropTypes from "prop-types";
import {getApprovalCategories, getCategories} from "../services/categories";
import {getDashboardInvoices} from "../services/accounts";
import BlockLoading from "./loadings/block_loading";
import {getCompany, getUserInformation} from "../services/login";
import CompanyLoading from "./loadings/dashboard_company_loader";
import CusNavBar from "./navbar";
import appContext from "../context/AppContext";
import {useDispatch, useSelector} from "react-redux";
import {addCompany, addUserInformation, removeUserInformation, updateResetPasswordStatus} from "../store/auth";
import {addCategories, addCategory, addInvoices} from "../store/dashboard";
import {getLawFirmData} from "../services/bond";
import {addLawFirmData} from "../store/bond";
import {insurance_companies} from "../constants";
import ReserveBondBlock from "../components/vermost/reserve_bond_block";
import BondCalculator from "../components/vermost/bond_calculator";
import VermostReserveBondNumberPopup from "../components/popups/vermost_reserve_bond_number_popup";
import ResetPasswordPopup from "../components/popups/reset_password_popup";
import {useSearchParams} from "react-router-dom";
import {formatDateMDY} from "../services/helper";
import {log} from "../utils/logger"
import {hasUserPermission} from "../utils/user_permission";
import {useSnackbar} from "notistack";
import {getVermostLawFirms, getVermostUsersPagination} from "../services/company_management";
import {addUsers} from "../store/company_management";

function Dashboard(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const context = useContext(appContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const categories = useSelector((state) => state.dashboard.categories);
    console.log(categories);
    const [showReserveBondNumberPopup, setShowReserveBondNumberPopup] = useState(false);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [loadProcessApproval, setLoadProcessApproval] = useState(true);
    const invoices = useSelector((state) => state.dashboard.invoices);
    const [invoicesLoading, setInvoicesLoading] = useState(true);
    const companyData = useSelector((state) => state.auth.company);
    const user_permission = useSelector((state) => state.auth.user.user_role_permission);
    const user_role_name = useSelector((state) => state.auth.user.user_role_name);
    const userID = useSelector((state) => state.auth.user.user_id);
    const isNewUser = useSelector((state) => state.auth.user.is_new);
    const firstName = useSelector((state) => state.auth.user.first_name);
    const lastName = useSelector((state) => state.auth.user.last_name);
    const should_reset_password = useSelector((state) => state.auth.user.should_reset_password);
    const userDepartments = useSelector((state) => Object.keys(state.auth.user.departments)[0]);
    const lead_id = searchParams.get('lead_id') ?? searchParams.get('customer_id');
    let existingCustomerParams = searchParams.get('existingCustomer') === 'true';
    const [isVermost, setIsVermost] = useState(false)
    const [showUserApproveInfo, setShowUserApproveInfo] = useState(false);
    const [showUserResetPassword, setShowUserResetPassword] = useState(false);

    const [updatePassword, setUpdatePassword] = useState(should_reset_password)
    const {enqueueSnackbar} = useSnackbar();
    let showAP = useSelector((state) => {
        if (!Object.keys(state.auth.user.departments).includes(userDepartments)) {
            return false;
        }
        return state.auth.user.departments[userDepartments].ap_permissions.length !== 0
    });

    const updatePasswordCheck = (flag) => {

        if (!flag) {
            setUpdatePassword(false);
            getUserInformation().then((userData) => {
                dispatch(removeUserInformation());
                dispatch(addUserInformation(userData));
            }).catch(error => {
                console.log(error)
                enqueueSnackbar('User information could not loaded.', {variant: "error"});
            });
        }
    }
    const getVermostUserList = async () => {
        let response = await getVermostUsersPagination("", "", "", "");
        console.log("response", response)
        if (response.status === 200) {
            const result = await response.json();
            return result.data;
        } else {
            const result = await response.json();
            enqueueSnackbar(result, {variant: "error"});
            return 0;
        }
    }

    useEffect(async () => {
        if (!localStorage.getItem('userdata')) {
            navigate("/");
        }

        if (searchParams.get('customer_id')) {
            existingCustomerParams = true;
        }

        document.title = "Dashboard";

        if (companyData.id === 0) {
            getCompany().then(async (response) => {
                dispatch(addCompany(response));
                if (response.name === 'Vermost') {
                    setIsVermost(true);
                    await getLawFirmData().then(async (res) => {
                        dispatch(addLawFirmData(res));
                    }).catch((err) => {
                        log(err);
                    });
                }
                localStorage.setItem('companyData', JSON.stringify(response));
                if (response.name === 'Portal Insurance' && !should_reset_password) {
                    if (lead_id) {
                        navigate({
                            pathname: '/insurance_portal/',
                            search: `?lead_id=${lead_id}&existingCustomer=${existingCustomerParams}`,
                        })
                    } else {
                        navigate("/insurance_portal");
                    }
                }
            }).catch(error => {
                log(error)
            });
        }

        // if (companyData.name === 'Vermost') {
        //     await getLawFirmData().then(async (res) => {
        //         dispatch(addLawFirmData(res));
        //     }).catch((err) => {
        //         log(err);
        //     });
        // }

        if (categories.length === 0) {
            getCategories().then((res) => {
                if (res === 401) {
                    localStorage.clear();
                    navigate('/');
                }
                const processCatnames = res.map((inv) => inv.category__name).filter((cat_name) => cat_name !== 'Accounts Payable');
                context.setProcessesCategoriesNames(processCatnames);
                dispatch(addCategories(res));
                setCategoriesLoading(false);
            }).catch(error => {
                log(error);
            });
        } else {
            setCategoriesLoading(false);
        }
        if (showAP && invoices.length === 0) {
            getDashboardInvoices(userDepartments).then((res) => {
                const cat_names = categories.map((cat) => cat['category__name']);
                dispatch(addInvoices(res));
                context.setInvoices(res);
                if (!cat_names.includes('Accounts Payable') && companyData.name !== 'FSI-ASI') {
                    dispatch(addCategory({category__name: "Accounts Payable"}));
                }
                setInvoicesLoading(false);
            }).catch(error => {
                log(error);
            });
        } else {
            setInvoicesLoading(false);
        }
    }, [])

    useEffect(async () => {
        log("companyData.name,", companyData.name);
        if (companyData.name === 'Vermost') {
            if (isNewUser) {
                setShowUserResetPassword(true);
                return;
            }
        }
        if (companyData.name === 'FSI-ASI') {
            navigate('/fsi');
        }
        let has_get_document_approval = false;
        categories.map((cat) => {
            if (cat.category__name === "Document Approval") {
                has_get_document_approval = true;
            }

        })
        if (loadProcessApproval === true && has_get_document_approval === false) {
            setLoadProcessApproval(false);
            if (has_get_document_approval !== true) {
                await getApprovalCategories().then((res) => {
                    if (Object.keys(res).length) {
                        log("getApprovalCategories res", res)
                        dispatch(addCategory(res));
                    }
                })
            } else {
                setLoadProcessApproval(false);
            }
        } else {
            setLoadProcessApproval(false);
        }
    })

    if (insurance_companies.includes(companyData.name)) {
        const date = new Date();
        const local_date = localStorage.getItem('lastLogin');
        if (local_date === undefined || local_date === null) {
            localStorage.setItem('lastLogin', formatDateMDY(date));
        } else if (new Date(local_date) < local_date) {
            localStorage.clear();
            navigate("/");
        }
        if (!should_reset_password) {
            if (lead_id) {
                navigate({
                    pathname: '/insurance_portal/',
                    search: `?lead_id=${lead_id}&existingCustomer=${existingCustomerParams}`,
                })
            } else {
                navigate("/insurance_portal");
            }
        }
    }

    return (<>
        <CusNavBar/>
        {showReserveBondNumberPopup === true &&
            <VermostReserveBondNumberPopup show={true} closePopup={setShowReserveBondNumberPopup}/>}
        {companyData.id !== 0 ? (<div>
            <header
                className="d-flex align-items-center justify-content-between text-dark text-decoration-none pt-3 pb-3 mb-3 border-bottom mx-8">
                <div className="fs-1">
                    Process Manager for {companyData.name}
                </div>
            </header>
            <div className="container d-flex justify-content-center mx-8">
                <div className="row col-6">
                    <h1>
                        Welcome {firstName} {lastName}
                    </h1>

                    <p className="fs-3 ">Please select a category.</p>
                </div>
            </div>
            <hr className="fs-1"/>
        </div>) : <CompanyLoading/>}

        <div className="container justify-content-center mx-8">
            <div className="row">

                <div
                    className={isVermost ? "col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12" : "col-md-12 col-sm-12 col-12"}>
                    <div className="row">
                        {(!categoriesLoading) ? categories.map((category, index) => {
                            if (category.category__name === 'Reserve Bond Number' && hasUserPermission(user_permission, "show_reserve_bond_in_dashboard")) {
                                return <ReserveBondBlock
                                    callback={setShowReserveBondNumberPopup}/>
                            } else if (category.category__name === 'Bonds' && hasUserPermission(user_permission, "show_approve_bond_in_dashboard")) {
                                return <Block key={index}
                                              category_name={hasUserPermission(user_permission, "change_bond_name_in_dashboard") ? "Approve Bonds" : category.category__name}
                                              processes={category.processes}
                                              setCategory={props.setCategory}/>

                            } else if (category.category__name !== 'Accounts Payable' && category.category__name !== 'Reserve Bond Number' && category.category__name !== 'Bond Calculator' && category.category__name !== 'Bonds') {
                                return <Block key={index} category_name={category.category__name}
                                              processes={category.processes}
                                              setCategory={props.setCategory}/>
                            }

                        }) : (<div className="col-6">
                            <BlockLoading/>
                        </div>)}
                        {(!invoicesLoading) ? (showAP &&
                            <APBlock category_name="Accounts Payable" invoices={invoices}/>) : (showAP &&
                            <div className="col-6">
                                <BlockLoading/>
                            </div>)}
                    </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="row">
                        {(!categoriesLoading) ? categories.map((category, index) => {
                            if (category.category__name === 'Bond Calculator') {
                                return <BondCalculator/>
                            }
                        }) : (<BlockLoading/>)}
                    </div>
                </div>
            </div>
        </div>


        {updatePassword && <ResetPasswordPopup show={true} closePopup={() => log('Password Updated.')}
                                               callback={updatePasswordCheck}
                                               showCloseButton={false}/>}
        <Footer/>
    </>)
}

Dashboard.propTypes = {
    setCategory: PropTypes.func.isRequired,
};

export default Dashboard;
